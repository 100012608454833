import {useClient} from '../../app/Ged';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { selectMetafieldsLabels, succeedMetafieldsLabelsFetch} from './clientFileSlice';
import useDocumentShare from "./useDocumentShare";

export default function useMetafieldsLabels() {
    const {request} = useClient();
    const metafieldsLabels = useSelector(selectMetafieldsLabels);
    const {documentShare} = useDocumentShare();
    const dispatch = useDispatch();

    useEffect(() => {
        if (metafieldsLabels) {
            return;
        }

        (async () => {
            const metafieldsReq = await request({
                method: 'get',
                url: `/api/v2/document_metafields/labels?validation_template_id=${documentShare.validation_template_id}`,
            })

            const metafieldsLabels = metafieldsReq.data;

            dispatch(succeedMetafieldsLabelsFetch(metafieldsLabels));
        })()
    }, [dispatch, request, metafieldsLabels, documentShare.validation_template_id]);

    return {metafieldsLabels};
}
