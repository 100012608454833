import mustache from "mustache/mustache.mjs";

export function userFullName(user) {
    const caseNameParts = [];
    if (user.gender === 1 || user.gender === 2) {
        caseNameParts.push(user.gender === 1 ? 'M.' : 'Mme');
    }
    caseNameParts.push(user.first_name, user.last_name.toUpperCase());

    return caseNameParts.join(' ');
}

export function parseValueInText(text, parsingValues, documentShare=null) {
    parsingValues = documentShare?.metafields?.reduce(
        (acc, metafield) =>({...acc, [`metafield_${metafield.guid}`]: metafield.value}), parsingValues
    );

    return mustache.render(text, parsingValues);
}
