import {useSelector} from 'react-redux';
import {selectDocumentShare, selectUser} from './clientFileSlice';
import {useEffect, useState} from "react";
import {parseValueInText, userFullName} from "../../utils/stringFormat";
import {selectSettings} from "../Settings/settingsSlice";


function WelcomeMessage() {
    const documentShare = useSelector(selectDocumentShare);
    const user = useSelector(selectUser);
    const settings = useSelector(selectSettings);
    const [welcomeMessage, setWelcomeMessage] = useState('');

    useEffect(() => {
        if (user && documentShare && settings) {
            setWelcomeMessage(
                parseValueInText(
                    settings.welcome_message,
                    {
                        "user": userFullName(user),
                        "company_name": settings.company_name,
                    },
                    documentShare
                )
            );
        }
    }, [user, documentShare, settings]);


    return <p style={{whiteSpace: "pre"}}>
        {welcomeMessage}
    </p>
}

export default WelcomeMessage;
