import PropTypes from "prop-types";
import {Input} from "@material-ui/core";
import {useEffect} from "react";

function MetafieldString({change, metafield}) {

    const getDefaultValue = () => {
        return metafield.default;
    }

    useEffect(() => {
        if (metafield.default) {
            change(getDefaultValue())
        }
    }, []);

    return (
        <Input
            type={'text'}
            defaultValue={metafield.default}
            onChange={(e) => change(e.target.value)}
        />
    );
}

MetafieldString.propTypes = {
    change: PropTypes.func,
    metafield: PropTypes.object,
};

export default MetafieldString;