import {configureStore} from '@reduxjs/toolkit';
import loginReducer from '../features/Login/loginSlice';
import clientFileReducer from '../features/ClientFile/clientFileSlice';
import settingsReducer from '../features/Settings/settingsSlice';

export default configureStore({
    reducer: {
        login: loginReducer,
        clientFile: clientFileReducer,
        settings: settingsReducer,
    },
});
