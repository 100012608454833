import PropTypes from "prop-types";
import {Input} from "@material-ui/core";
import {useEffect, useState} from "react";

function MetafieldDate({change, metafield}) {

    const [value, setValue] = useState(null);

    const getDefaultValue = () => {
        if (metafield?.default === null) return
        let split = metafield.default.split('-');
        return `${split[2]}-${split[1]}-${split[0]}`;
    }

    const reverseDate = (date) => {
        let split = date.split('-');
        return `${split[2]}-${split[1]}-${split[0]}`;
    }

    useEffect(() => {
        if (metafield.default !== null) {
            setValue(getDefaultValue());
            change({
                'value': reverseDate(getDefaultValue()),
                'reminder': null
            })
        }
    }, []);

    return (
        <Input
            type={'date'}
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                change({
                    'value': reverseDate(e.target.value),
                    'reminder': null
                })
            }}
        />
    );
}

MetafieldDate.propTypes = {
    change: PropTypes.func,
    metafield: PropTypes.object,
};

export default MetafieldDate;