import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useClient } from "../../app/Ged";
import { Alert } from "@material-ui/lab";
import useDocumentShare from "./useDocumentShare";
import { useSelector } from "react-redux";
import { selectSettings } from "../Settings/settingsSlice";
import useMetafields from "./useMetafields";
import MetafieldBaseInput from "../../component/Metafields/MetafieldBaseInput";

const useStyle = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(4),
    gap: theme.spacing(2),
  },
  fileInputWrapper: {
    display: "flex",
  },
  fileInputLabel: {
    flex: 1,
  },
  fileInputButton: {
    display: "flex",
    alignItems: "flex-end",
    "& label": {},
  },
}));

function FileForm({ reloadDocuments }) {
  const classes = useStyle();
  const { documentShare } = useDocumentShare();
  const { metafields } = useMetafields();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(null);
  const { request, isLoading, error, reset } = useClient();
  const settings = useSelector(selectSettings);
  const fileTypes = settings.document_type_list;
  const hasFileTypes = fileTypes?.length;
  const [metafieldsValues, setMetafieldsValues] = useState({});
  const [metafieldsValid, setMetafieldsValid] = useState(false);

  useEffect(() => {
    checkMetafields();
  }, [metafieldsValues]);

  useEffect(() => {
    checkMetafields();
  }, [file]);

  useEffect(() => {
    checkMetafields();
  }, [open]);

  function handleFileSelected(files) {
    if (files.length > 0) {
      setFile(files[0]);
    }
  }

  function handleClose() {
    setFile(null);
    setOpen(false);
    setIsUploading(false);
    setProgress(null);
    setMetafieldsValid(false);
    setMetafieldsValues({})
    reset();
  }

  async function handleSubmit() {

    setIsUploading(true);

    const firstResponse = await request({
      method: "POST",
      url: "/api/v2/documents",
      data: {
        name: file.name,
        size: file.size,
        document_share_guid: documentShare.guid,
        folder_guid: documentShare.folders?.[0].guid,
        metafields: { ...metafieldsValues },
      },
    });

    if (!firstResponse) {
      setIsUploading(false);
      return;
    }

    const formData = new FormData();
    formData.append("contents", file);

    setIsUploading(true);
    await request({
      method: "POST",
      url: `/api/v2/documents/${firstResponse.data.guid}/contents?run_validation=1`,
      data: formData,
      headers: {
        "Content-type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        setProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    });

    if (documentShare.document_share_template_guid) {
      await request({
        method: "POST",
        url: `/api/v2/document_shares/${documentShare.guid}/run_validation`,
      });
    }

    handleClose();
    reloadDocuments();
  }

  const checkMetafields = () => {
    if (!metafields) {
      setMetafieldsValid(false);
      return;
    }
    let requiredMetafields = metafields.filter(
      (metafield) => metafield.required,
    );
    let missingMetafields = requiredMetafields.filter(
      (metafield) => !metafieldsValues[metafield.id],
    );

    if (missingMetafields.length > 0) {
      setMetafieldsValid(false);
    } else {
      setMetafieldsValid(true);
    }
  };

  const renderMetafields = () => {
    if (metafields) {
      return metafields.map((metafield) => {
        return (
          <MetafieldBaseInput
            key={metafield.id}
            metafield={metafield}
            metafieldsValues={metafieldsValues}
            setMetafieldsValues={setMetafieldsValues}
          />
        );
      });
    }
  };

  return (
    <>
      <div style={{ textAlign: "right", marginBottom: "1em" }}>
        {hasFileTypes !== 0 ? (
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpen(true)}
          >
            Joindre un document
          </Button>
        ) : null}
      </div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-title">Joindre un document</DialogTitle>
        {error && <Alert severity="error">{error}</Alert>}
        <DialogContent>

          <div className={`${classes.fileInputWrapper} ${classes.formControl}`}>
            <div className={classes.fileInputLabel}>
              <FormControl>
                <InputLabel required={true}>Importer un fichier</InputLabel>
                <Input
                  id="file-selection-input2"
                  type="text"
                  value={file ? file.name : ""}
                  readOnly={true}
                  disabled={isLoading || isUploading}
                />
              </FormControl>
            </div>
            <div className={classes.fileInputButton}>
              <Button
                variant="contained"
                component="label"
                size="small"
                disabled={isLoading || isUploading}
              >
                {file ? "Changer de fichier" : "Choisir un fichier"}
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleFileSelected(e.target.files)}
                />
              </Button>
            </div>
          </div>
          {
            renderMetafields()
          }
        </DialogContent>
        <DialogActions>
          {(isLoading || isUploading) && <CircularProgress />}
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={
              isLoading ||
              isUploading ||
              !metafieldsValid
            }
          >
            Valider
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            disabled={isLoading}
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FileForm;
