import {FormControl, Input, InputLabel} from "@material-ui/core";
import MetafieldString from "./MetafieldString";
import MetafieldNumber from "./MetafieldNumber";
import MetafieldDate from "./MetafieldDate";
import MetafieldEnum from "./MetafieldEnum";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectSettings} from "../../features/Settings/settingsSlice";


const numeroFacture = 'B0EA67A5-BF47-441F-965F-B06BF325E832';
const typeFacture = '4037ED56-A83B-47F3-844C-3B75423940BA';
const codeComptableFacture = '265D44BE-C04C-4022-B188-021441D530A6';
const dateFacture = '3B9B4231-1D91-450C-BD3E-EF9EEDC62999';
const dateEcheance = '45558AC7-988F-42B8-899B-618C5150DD33';
const fournisseur = 'ED074FB6-6843-472C-B7B4-3E4CAB0A0A5D';
const siret = '23FF54CC-7526-489E-A0A5-3A0F8EF890B3';
const codeFournisseur = '8BC68B69-05B6-434B-8BB6-2C7C98187CDD';
const codeComptableFournisseur = '47EEC83E-86FB-4DF1-8415-C6C0C3E7ADE8';
const montantHT = '8C4FDC73-05F7-4452-BD37-22CAEC5B8C99';
const montantTVA = '09E47C0C-2216-4113-ABB7-3A6D193CF382';
const montantTTC = '482BF9B1-CAC7-4124-946A-201FB4AF58B0';
const tauxTVA = '25481C31-383F-4688-A0CE-EB5F1633CEE8';
const codeComptableTVA = 'AC978CC2-70A1-4440-AAEB-E128B4F85E2B';
const numeroTvaIntracommunautaire = '92153F99-34BE-4C70-847B-3BFDDB96F7FE';
const modeOfPayment = 'D88A9F01-4264-4B63-A4D3-8528D648B556';
const reference = '8658577B-1669-4DA2-8C65-773391AAE511';
const currency = 'D33BD1F2-233E-4762-8D70-B4E77F6E2711';
const extractedStatus = '9296B0E0-0B35-4016-A119-1CBA719DDF7E';

const kofaxGuid = [
    numeroFacture,
    typeFacture,
    codeComptableFacture,
    dateFacture,
    dateEcheance,
    fournisseur,
    siret,
    codeFournisseur,
    codeComptableFournisseur,
    montantHT,
    montantTVA,
    montantTTC,
    tauxTVA,
    codeComptableTVA,
    numeroTvaIntracommunautaire,
    modeOfPayment,
    reference,
    currency,
    extractedStatus
];


function MetafieldBaseInput ({metafield, metafieldsValues, setMetafieldsValues}) {
    const settings = useSelector(selectSettings);
    const [filtredMetafield, setFiltredMetafield] = useState(metafield)

    useEffect(() => {
        if (metafield.id === settings.document_type_metafield) {
            let newMetafield = {...metafield};

            newMetafield.enum = newMetafield.enum.filter((value) => {
                let type_authorization = settings.document_type_list.map((type) => type.value)

                return type_authorization.includes(value)
            })

            setFiltredMetafield(newMetafield);
        }
    }, [metafield])

    if (kofaxGuid.includes(metafield.guid)) {
        return null;
    }

    const onChange = (value) => {
        setMetafieldsValues({...metafieldsValues, [metafield.id]: value})
    }

    const inputType = {
        'string': {
            component: <MetafieldString metafield={filtredMetafield} change={onChange}/>
        },
        'number': {
            component: <MetafieldNumber metafield={filtredMetafield}  change={onChange}/>
        },
        'date': {
            component: <MetafieldDate metafield={filtredMetafield} change={onChange}/>
        },
        'gdpr': {
            component: <MetafieldDate metafield={filtredMetafield} change={onChange}/>
        },
        'enum': {
            component: <MetafieldEnum metafield={filtredMetafield} change={onChange}/>
        }
    }

    return (
        <FormControl>
            <InputLabel required>{filtredMetafield.name}</InputLabel>
            {inputType[filtredMetafield.type].component}
        </FormControl>
    )
}

export default MetafieldBaseInput;