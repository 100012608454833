import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {logout} from "../../features/Login/loginSlice";

export default function LogoutPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());
    });

    return null;
}
