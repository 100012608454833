import {useClient} from '../../app/Ged';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectDocumentShare, selectUser, succeedDocumentShareFetch, succeedUserFetch} from './clientFileSlice';

export default function useDocumentShare() {
    const {request} = useClient();
    const documentShare = useSelector(selectDocumentShare);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    useEffect(() => {
        if (documentShare) {
            return;
        }

        (async () => {
            const documentShares = await request({
                method: 'get',
                url: '/api/v2/document_shares/linked',
            })
            const documentShare = documentShares ? documentShares.data[0] : null;
            dispatch(succeedDocumentShareFetch(documentShare));
        })()
    }, [dispatch, request, documentShare]);

    useEffect(() => {
        if (user) {
            return;
        }

        (async () => {
            const response = await request({method: 'get', url: '/api/v2/me'});

            if (!response || !response.data) {
                return;
            }

            dispatch(succeedUserFetch(response.data));
        })()
    }, [dispatch, request, user]);

    return {documentShare};
}
