import {makeStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {selectSettings} from '../../features/Settings/settingsSlice';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0, 'auto', 4),
        maxWidth: 800,
    },
    large: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    simple: {
        textAlign: 'center',
    },
    logoWrapper: {
        textAlign: 'center',
        width: '100%',
    },
    logo: {
        display: 'block',
        maxWidth: 200,
        maxHeight: 200,
        margin: ({finalVariant}) => finalVariant === 'large' ? 0 : theme.spacing(0, 'auto'),
    },
}));

function Header({variant, handleLogout}) {
    const finalVariant = variant || 'large';
    const classes = useStyles({finalVariant});
    const settings = useSelector(selectSettings);

    return <div className={`${classes.root} ${classes[finalVariant]}`}>
        <Link to="/" className={classes.logoWrapper}>
            {
                settings.logo_url && <img src={settings.logo_url} alt={`Logo ${settings.company_name}`} className={classes.logo} />
            }
        </Link>
        {
            finalVariant === 'large' &&
            <div>
                <Button className={classes.logout} variant="outlined" color="secondary" size="small" onClick={handleLogout}>Déconnexion</Button>
            </div>
        }
    </div>;
}

export default Header;
