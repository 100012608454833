import * as R from 'ramda';

function findOneMetafieldById(metafields, id, valuePath) {
    if (!metafields) {
        return null;
    }

    const metas = metafields.filter(metafield => metafield.id === id);

    if (!metas.length) {
        return null;
    }

    if (valuePath) {
        return R.path(valuePath, metas[0]);
    }

    return metas[0];
}

function findMetafieldsById(metafields, id) {
    if (!metafields) {
        return [];
    }

    const metas = metafields.filter(metafield => metafield.id === id);

    if (!metas.length) {
        return [];
    }

    return metas;
}

function findOneMetafieldByGuid(metafields, guid, valuePath) {
    if (!metafields) {
        return null;
    }

    const metas = metafields.filter(metafield => metafield.guid === guid);

    if (!metas.length) {
        return null;
    }

    if (valuePath) {
        return R.path(valuePath, metas[0]);
    }

    return metas[0];
}

const MetafieldHelper = {
    findOneMetafieldById,
    findMetafieldsById,
    findOneMetafieldByGuid,
};

export {findOneMetafieldById, findMetafieldsById, findOneMetafieldByGuid};

export default MetafieldHelper;
