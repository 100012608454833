import {makeStyles} from '@material-ui/styles';
import {Link as BaseLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontSize: props => props.size === 'sm' ? '80%' : '100%'
    }
}));

export default function Link(props) {
    const classes = useStyles({size: props.size});
    const classNames = props.className ? [props.className, classes.root] : [classes.root]

    return <BaseLink {...props} className={classNames.join(' ')} />
}
