import {useDispatch, useSelector} from 'react-redux';
import {selectSettings, setSettings} from './settingsSlice';
import {useEffect} from 'react';
import {createClient} from '../../app/Ged';

export default function useSettings() {
    const settings = useSelector(selectSettings);
    const dispatch = useDispatch();

    useEffect(() => {
        async function loadSettings() {
            const {request} = createClient();
            try {
                const response = await request({method: 'get', url: '/api/v2/customer_portal_settings'});
                const data = response.data;

                if (data.favicon_url) {
                    const faviconEl = document.querySelector('#favicon');
                    faviconEl.href = data.favicon_url;
                }

                dispatch(setSettings({settings: {...data, loaded: true}}));
            } catch (error) {
                console.error(error);
            }
        }
        loadSettings();
    }, [dispatch])

    return {settings};
}
