import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import {selectDocumentShare, selectUser} from './clientFileSlice';
import {parseValueInText, userFullName} from "../../utils/stringFormat";
import {useEffect, useState} from "react";
import {selectSettings} from "../Settings/settingsSlice";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'right',
        fontWeight: 'bold',
        marginBottom: theme.spacing(6),
    },
    p: {
        marginBottom: theme.spacing(1),
        textAlign: 'right',
    },
    state: {
        display: 'inline-block',
        background: ({state}) => getStateColor(state, theme),
        padding: theme.spacing(.5, 2),
        fontSize: '.7rem',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        borderRadius: '1em',
        textAlign: 'right',
        margin: '0 0 0 auto',
    },
}));

function FileInfo() {
    const documentShare = useSelector(selectDocumentShare);
    const user = useSelector(selectUser);
    const state = documentShare.state
    const classes = useStyle({state});
    const settings = useSelector(selectSettings);
    const [fileInfo, setFileInfo] = useState('');

    useEffect(() => {
        if (user && documentShare) {
            setFileInfo(
                parseValueInText(
                    settings.file_info,
                    {
                        "user": userFullName(user),
                        "document_share_name": documentShare.name,
                    },
                    documentShare,
                )
            );
        }
    }, [user, documentShare, settings]);

    return <div className={classes.root}>
        <p className={classes.p} style={{whiteSpace: "pre"}}>
            {fileInfo}
        </p>
        {
            documentShare.validation_template_id &&
            <div className={classes.state}>
                {getStateName(documentShare.state)}
            </div>
        }
    </div>
}

function getStateName(state) {
    switch (state) {
        case 'complete':
            return 'Dossier complet';

        case 'canceled':
            return 'Dossier annulé';

        case 'closed':
            return 'Dossier clôturé';

        case 'running':
        default:
            return 'Dossier en cours';
    }
}

function getStateColor(state, theme) {
    switch (state) {
        case 'complete':
            return '#4ba301';

        case 'canceled':
            return theme.palette.primary.main;

        case 'closed':
            return '#4ba301';

        case 'running':
        default:
            return theme.palette.warning.main;
    }
}

export default FileInfo;
