import {useClient} from "../../app/Ged";
import {useEffect, useState} from "react";

class Translator {

    constructor(translations) {
        this.translations = translations
    }

    format(key, values) {
        let str = this.translations[key] || key;
        values.forEach((value, index) => {
            str = str.replace('%var%', value);
        });
        return str;
    }

    translate(key, values = []) {
        if (this.translations[key]) {
            return this.format(key, values);
        }
        return key;
    }
}


export default function useTranslator(translationsKey) {
    const request = useClient().request;
    const [translations, setTranslations] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (loading) {
                return;
            }
            setLoading(true)

            let apiArgs = translationsKey.map(translation => `keys=${translation}`).join('&');
            apiArgs += '&lang=fr';


            const response = await request({
                method: 'get',
                url: '/api/v2/translations?' + apiArgs,
            });

            if (!response || !response.data) {
                return;
            }

            setTranslations(response.data);
        })()
    }, [request, translationsKey]);

    return new Translator(translations)
}