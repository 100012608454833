import {DataGrid, frFR} from '@material-ui/data-grid';
import {SystemUpdateAlt, Visibility} from '@material-ui/icons';
import {Button, ButtonGroup, useMediaQuery, useTheme} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {selectDocuments} from './clientFileSlice';
import {Alert} from '@material-ui/lab';
import useDocuments from './useDocuments';
import {useEffect, useState} from 'react';
import FileForm from './FileForm';
import {useClient} from '../../app/Ged';
import FileSaver from 'file-saver';
import useSettings from "../Settings/useSettings";
import useTranslator from "../translations/useTranslator";

function Documents({documentShare}) {
    const documents = useSelector(selectDocuments);
    const {isLoading, error, fetchDocuments} = useDocuments();
    const {request} = useClient();
    const {settings} = useSettings();
    const [columns, setColumns] = useState([]);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const translator = useTranslator(['other']);
    const [translatedDocuments, setTranslatedDocuments] = useState([]);

    useEffect(() => {
        const translated = documents.map(document => {
            const name = translator.translate(document.name)
            return {...document, name};
        });

        setTranslatedDocuments(translated);
    }, [documents]);

    useEffect(() => {
        let baseColumns = [
            {field: 'filename', headerName: 'Nom', width: isSm ? undefined : 250, flex: isSm ? true : undefined},
            {field: 'name', headerName: 'Type de document', width: isXs ? undefined : 250, flex: isXs ? true : undefined},
            {field: 'date', headerName: 'Date import', width: 150},
            {field: 'actions', headerName: null, width: isXs ? 90 : 150, renderCell: actionCellRenderer}
        ];

        if (isXs) {
            baseColumns = baseColumns.filter(({field}) => field !== 'name' && field !== 'date');
        } else if (isSm) {
            baseColumns = baseColumns.filter(({field}) => field !== 'date');
        }

        if (!settings?.document_type_metafield || !documentShare.document_share_template_guid) {
            baseColumns = baseColumns.filter(({field}) => field !== 'name');
        }

        setColumns(baseColumns);
    }, [isXs, isSm]);

    const reloadDocuments = () => {
        fetchDocuments();
    };

    useEffect(reloadDocuments, [fetchDocuments]);

    async function getFileContent(guid) {
        const response = await request({
            url: `/api/v2/documents/${guid}/contents`,
            method: 'get',
            responseType: 'blob',
        });
        let fileType = response.headers['content-type'];

        if (fileType && fileType.startsWith("b'") && fileType.endsWith("'")) {
            fileType = fileType.substring(2, fileType.length - 1);
        }

        return new Blob([response.data], {type: fileType|| 'application/octet-stream'});
    }

    async function downloadFile(guid, filename) {
        const blob = await getFileContent(guid);
        FileSaver.saveAs(blob, filename, {autoBom: true});
    }

    async function showFile(guid) {
        const blob = await getFileContent(guid, 'application/pdf');
        const url = window.URL.createObjectURL(blob);
        const w = window.open(url, '_blank');
        w && w.focus();
        window.URL.revokeObjectURL(url);
    }

    const actionCellRenderer = (params) => {
        return <ButtonGroup variant="text">
            <Button onClick={() => downloadFile(params.row.guid, params.row.filename)}><SystemUpdateAlt color={'primary'}/></Button>
            <Button onClick={() => showFile(params.row.guid)}><Visibility color={'primary'}/></Button>
        </ButtonGroup>;
    };

    return <div className="Documents">
        {error && <Alert severity="error">{error}</Alert>}
        <FileForm reloadDocuments={reloadDocuments}/>
        <div id='ouhouuuh'>
            <DataGrid rows={translatedDocuments}
                      columns={columns}
                      autoHeight={true}
                      disableSelectionOnClick={true}
                      hideFooter={true}
                      disableColumnFilter={true}
                      disableColumnMenu={true}
                      loading={isLoading}
                      localeText={{
                          ...frFR.props.MuiDataGrid.localeText,
                          noRowsLabel: 'Aucun document',
                      }}
            />
        </div>
    </div>;
}

export default Documents;
