import {useClient} from '../../app/Ged';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    selectMetafields,
    succeedMetafieldsFetch
} from './clientFileSlice';
import useDocumentShare from "./useDocumentShare";

export default function useMetafields() {
    const {request} = useClient();
    const metafields = useSelector(selectMetafields);
    const {documentShare} = useDocumentShare();
    const dispatch = useDispatch();

    useEffect(() => {
        if (metafields) {
            return;
        }

        (async () => {
            const metafieldsReq = await request({
                method: 'get',
                url: `/api/v2/document_shares/${documentShare.guid}/document_metafields`,
                data: {
                    limit: 1000,
                    page: 1,
                    document_share: documentShare.guid,
                }
            })

            const metafields = metafieldsReq.data;

            dispatch(succeedMetafieldsFetch(metafields));
        })()
    }, [dispatch, request, metafields, documentShare.guid]);

    return {metafields};
}
