import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from "react-redux";
import {selectSettings} from "../../features/Settings/settingsSlice";

const useStyle = makeStyles(theme => ({
    root: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    logo: {
        display: 'block',
        margin: 'auto',
        height: '5vh',
        minHeight: 20,
    },
}));

function Footer() {
    const classes = useStyle();
    const settings = useSelector(selectSettings);

    return <div className={classes.root}>
        <img src={settings?.footer_logo_url?.res_url} alt="Logo Powered by XEFI/TA"  className={classes.logo} />
    </div>;
}

export default Footer;
