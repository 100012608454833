import loginSuccess from '../../mocks/login/login.success.json';
import documentsSuccess from '../../mocks/client_file/get_documents.success.json';
import postDocumentSuccess from '../../mocks/client_file/post_document.success.json';
import document from '../../mocks/client_file/document.pdf';
import settings from '../../mocks/settings/get_settings.success.json';
import folders from '../../mocks/client_file/post_document_share_search.success.json';
import axios from 'axios';

async function getFakeResponseForOptions(options) {
    if (options.url === '/api/v2/authenticate') {
        return {data: loginSuccess};
    }

    if (options.url === '/api/v2/documents' && options.method.toLowerCase() === 'post') {
        return {data: postDocumentSuccess};
    }

    if (options.url === '/api/v2/document_shares/linked' && options.method.toLowerCase() === 'get') {
        return {data: folders};
    }

    if (options.url.match(/\/api\/v2\/document_shares\/.*/g) && options.method.toLowerCase() === 'get') {
        return {data: documentsSuccess};
    }

    if (options.url.match(/\/api\/v2\/documents\/.*\/contents/g) && options.method.toLowerCase() === 'get') {
        const response = await axios.request({
            url: document,
            method: 'get',
            responseType: 'blob',
        });

        response.headers['content-type'] = 'application/octet-stream';

        return response;
    }

    if (options.url === '/api/v2/customer_portal_settings' && options.method.toLowerCase() === 'get') {
        return {data: settings};
    }

    return {data: null};
}

function fakeClient(config) {
    return {
        request: async options => {
            console.debug('fake client request', options, config)

            return new Promise((resolve, reject) => {
                setTimeout(async () => {
                    const data = await getFakeResponseForOptions(options);
                    console.debug('fake client response', data);
                    resolve(data);
                }, 1000);
            });
        },
    };
}

export default fakeClient;
