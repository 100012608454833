import {createSlice} from '@reduxjs/toolkit';
import rs from 'jsrsasign';
import {clearClientFile} from "../ClientFile/clientFileSlice";

const createInitialState = () => ({
    token: null,
    isLoading: false,
    error: null,
});

export const loginSlice = createSlice({
    name: 'login',
    initialState: createInitialState(),
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload.token;
        },
        succeedAuthentication: (state, action) => {
            state.token = action.payload.token;
            state.isLoading = false;
            state.error = null;
        },
        clearAuthentication: (state, action) => {
            const newState = createInitialState();

            if (action.payload.error) {
                newState.error = action.payload.error;
            }

            return newState;
        }
    },
});

export const {succeedAuthentication, clearAuthentication} = loginSlice.actions;

export const selectToken = state => state.login.token;
export const selectLoginError = state => state.login.error;

export const parseLoginResponse = body => {
    const jwtParts = body.access_token.split('.');
    const payloadStr = rs.b64toutf8(jwtParts[1]);
    const payload = rs.KJUR.jws.JWS.readSafeJSONString(payloadStr);

    return {token: body.access_token, payload};
};

export const logout = (error) => async dispatch => {
    await localStorage.removeItem('auth_token');
    dispatch(clearAuthentication({error}));
    dispatch(clearClientFile());
};

export default loginSlice.reducer;
