import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {logout, selectToken} from '../../features/Login/loginSlice';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import fakeClient from './fakeClient';

export {fakeClient};

if (process.env.REACT_APP_GED_API_URL === undefined) {
    console.error('Missing env variable `REACT_APP_GED_API_URL`');
}

export const createClient = (token) => {
    const config = {baseURL: `${process.env.REACT_APP_GED_API_URL}`};

    if (token) {
        config.headers = {'Authorization': 'bearer ' + token};
    }

    if (process.env.REACT_APP_GED_USE_FAKE_CLIENT === '1') {
        return fakeClient({token});
    }

    return axios.create(config);
};

export const useClient = () => {
    const token = useSelector(selectToken);
    const client = useMemo(() => createClient(token), [token]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        return () => {
            setIsLoading(false);
            setError(null);
        }
    }, []);

    const reset = () => {
        setIsLoading(false);
        setError(null);
    }

    const request = async options => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await client.request(options);
            setIsLoading(false);

            return response;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                dispatch(logout('Votre session a expiré, veuillez-vous reconnecter.'));
                history.push('/');
            } else {
                setIsLoading(false);
                setError(err.response ? err.response.status : "Unkown error");
            }
            return null;
        }
    }

    return {
        request: useCallback(request, [client, dispatch, history]),
        isLoading,
        error,
        reset,
    };
}

export default createClient();
