import {makeStyles} from '@material-ui/styles';
import {Typography} from '@material-ui/core';
import PasswordResetForm from '../../features/Login/PasswordResetForm';
import useQuery from '../../utils/useQuery';
import {Alert, AlertTitle} from '@material-ui/lab';
import {useClient} from '../../app/Ged';
import Link from '../../component/Link/Link';
import {useHistory} from 'react-router-dom';
import {useState} from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(8, 'auto'),
        maxWidth: 800,
    },
    form: {
        margin: theme.spacing(6, 'auto')
    }
}));

export default function PasswordResetPage() {
    const classes = useStyles();
    const queryParams = useQuery();
    const {request, error, isLoading} = useClient();
    const history = useHistory();
    const [complete, setComplete] = useState(false);

    if (!queryParams.has('token')) {
        return <>
            <h1>Ré-initialisation de votre mot de passe</h1>
            <Alert severity="error">
                <AlertTitle>
                    Les paramètres pour ré-initialiser votre compte sont manquants. <br/>
                    <Link to="/">Cliquez-ici pour retourner à l'accueil</Link>
                </AlertTitle>
            </Alert>
        </>
    }

    const resetPassword = async (data) => {
        data.token = queryParams.get('token');

        const response = await request({
            method: 'POST',
            url: `/api/v2/users/${queryParams.get('uguid')}/reset_password`,
            data,
        });

        if (response) {
            setComplete(true);
            setTimeout(() => {
                history.push('/');
            }, 8000);
        }
    }

    const callbackDoneMessage = <>
        Votre mot de passe a bien été mis à jour, vous pouvez dès à présent l'utiliser pour vous connecter ! <br/>
        Vous allez être redirigé sur la page de connexion d'ici quelques secondes.
    </>;

    return <div className={classes.root}>
        <h1>Ré-initialisation de votre mot de passe</h1>
        {
            complete ? <>
                    <Alert severity="success"><AlertTitle>{callbackDoneMessage}</AlertTitle></Alert>
                </>
                : <>
                <Typography>
                    Remplissez le formulaire suivant afin de ré-initialiser votre mot de passe.
                </Typography>

                <div className={classes.form}>
                    <PasswordResetForm callback={resetPassword} isLoading={isLoading} error={error} />
                </div>
            </>
        }

        <p style={{textAlign: 'center'}}>
            <Link to="/" size="sm">Retour au formulaire de connexion</Link>
        </p>
    </div>;
}
