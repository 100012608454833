import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Footer from './component/Footer';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {selectToken, logout} from './features/Login/loginSlice';
import {useDispatch, useSelector} from 'react-redux';
import Header from './component/Header';
import useTheme from './features/Theme/useTheme';
import useLogin from './features/Login/useLogin';
import {Switch, Route, Redirect} from 'react-router-dom';
import {LoginPage, PasswordResetRequestPage, PasswordResetPage, PortalPage, ActivatePage, LogoutPage} from './pages';
import {Helmet} from "react-helmet";
import {selectSettings} from "./features/Settings/settingsSlice";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        minHeight: "100vh",
        position: "relative",
        padding: 100,
        margin: 0,
        backgroundImage: props => `url(${props.backgroundImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    content: {
        paddingBottom: theme.spacing(7),
    },
}));

function App() {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const {theme} = useTheme();
    const settings = useSelector(selectSettings);
    useLogin();

    const classes = useStyles({backgroundImage: settings?.background_image_url});

    if (!theme) {
        return <></>
    }

    return (
        <>
            {settings?.company_name ?
                <Helmet>
                    <title>{`${settings.company_name} - Portail Client`}</title>
                </Helmet> : ''
            }
            <Router>
                <ThemeProvider theme={theme}>
                    <Router>
                            <div className={classes.root}>
                                {
                                    token ? <>
                                        <Header variant="" handleLogout={() => dispatch(logout())}/>
                                        <div className={classes.content}>
                                            <Switch>
                                                <Route exact path="/">
                                                    <PortalPage/>
                                                </Route>
                                                <Route path="/activate">
                                                    <LogoutPage/>
                                                </Route>
                                                <Redirect push to="/" />
                                            </Switch>
                                        </div>
                                    </> : <>
                                        <Header variant="simple" handleLogout={() => dispatch(logout())}/>
                                        <div className={classes.content}>
                                            <Switch>
                                                <Route exact path="/">
                                                    <LoginPage/>
                                                </Route>
                                                <Route path="/activate">
                                                    <ActivatePage/>
                                                </Route>
                                                <Route path="/mot-de-passe-oublié">
                                                    <PasswordResetRequestPage/>
                                                </Route>
                                                <Route path="/reset_password">
                                                    <PasswordResetPage/>
                                                </Route>
                                                <Redirect push to="/" />
                                            </Switch>
                                        </div>
                                </>
                            }
                            <Footer/>
                        </div>
                    </Router>
                </ThemeProvider>
            </Router>
        </>
    );
}

export default App;
