import {useDispatch} from 'react-redux';
import {parseLoginResponse, succeedAuthentication} from './loginSlice';
import {useEffect} from 'react';

export default function useLogin() {
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            try {
                const savedToken = JSON.parse(await localStorage.getItem('auth_token'));
                if (savedToken) {
                    dispatch(succeedAuthentication(parseLoginResponse(savedToken)));
                }
            } catch (error) {
                localStorage.removeItem('auth_token');
            }
        })()
    }, [dispatch]);
}
